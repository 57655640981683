import React from "react";

const IconLogout = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 34 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 11.8789L22.3333 18.2122M22.3333 18.2122L16 24.5456M22.3333 18.2122H1.75M2.28488 10.2956C5.02254 5.56298 10.1394 2.37891 16 2.37891C24.7445 2.37891 31.8333 9.46773 31.8333 18.2122C31.8333 26.9567 24.7445 34.0456 16 34.0456C10.1394 34.0456 5.02254 30.8615 2.28488 26.1289"
        stroke="#8A5A16"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default IconLogout;
