export const PathRoute = {
  Home: "/",
  XacThuc: "/xac-thuc",
  Login: "/login",
  Dashboard: "/dashboard",
  Page404: "/404",

  KeKhaiTrucTuyen: "/ke-khai-tt",
  TraCuuHoSo: "/tra-cuu-hs",
  ThongTinCaNhan: "/thong-tin-cn",
  GioiThieu: "/gioi-thieu",
  HoTro: "/ho-tro",
  HuongDanThuTuc: "/huong-dan",
  ThongBao: "/thong-bao",
  ChinhSach: "/chinh-sach",
  DieuKhoan: "/dieu-khoan",
};
