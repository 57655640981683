import React, { Suspense } from "react";
import Header from "../../components/Header";
import Loading from "../../components/Loading";
import Footer from "../../components/Footer";
import { IDefaultLayout } from "../../interfaces";

const DefaultLayout: React.FC<IDefaultLayout> = ({ children, isNoDefault }) => {
  //! define

  //! state

  //! function

  //! useEffect

  //! render
  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Header />
      <div className="max-width-1200" style={{ flex: "1 0 auto" }}>
        <main>
          <Suspense fallback={<Loading />}>{children}</Suspense>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default DefaultLayout;
