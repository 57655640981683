import React from 'react';

const Loading = () => { 
//! define

//! state

//! function

//! useEffect

//! render
return (
<div>...loading</div>
)

}

export default Loading
