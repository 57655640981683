import { Grid, Image, MenuProps, Button as ButtonAntd } from "antd";
import Logo from "../../assets/images/logoBMN.svg";
import { Avatar, Drawer, Dropdown } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import authServices from "../../services/authServices";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import Button from "../CustomButton";

const HeaderLogin = () => {
  //! define
  const { useBreakpoint } = Grid;
  const { md } = useBreakpoint();
  const user = authServices.getUserInStorage("user");
  const [openMiniMenu, setOpenMiniMenu] = useState<boolean>(false);
  const [items, setItems] = useState<MenuProps["items"]>([]);
  const location = useLocation();
  const pathName = location.pathname;

  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_IDP_URL}?client_id=${
      process.env.REACT_APP_IDP_CLIENT_ID
    }&redirect_uri=${encodeURIComponent(
      `${window.location.origin}/xac-thuc`
    )}&response_mode=fragment&response_type=code&scope=openid`;
  };

  //! useEffect

  //! render
  // return (
  //   <div className="header-login">
  //     <div className="header-login__logo">
  //       <Image src={Logo} preview={false} />
  //       <div className="text-welcome">
  //         CỔNG HÀNH CHÍNH ĐIỆN TỬ
  //         <br /> GIÁO HỘI PHẬT GIÁO VIỆT NAM
  //       </div>
  //     </div>
  //     <div className="header-login__button">
  //       <Button
  //         title="Đăng nhập"
  //         className="custom-button"
  //         onClick={handleLogin}
  //       />
  //     </div>
  //   </div>
  // );

  const [menu, setMenu] = useState([]);

  const menuCustom: any = [
    {
      key: "POT_HOME",
      code: "POT_HOME",
      name: "Trang chủ",
      parentCode: null,
      path: "/",
      ordinal: 1,
      children: [],
    },
    {
      key: "POT_KKTT",
      code: "POT_KKTT",
      name: "Kê khai trực tuyến",
      parentCode: null,
      path: "/ke-khai-tt",
      ordinal: 1,
      children: [],
    },
    {
      key: "POT_TCHS",
      code: "POT_TCHS",
      name: "Tra cứu hồ sơ",
      parentCode: null,
      path: "/tra-cuu-hs",
      ordinal: 1,
      children: [],
    },
    {
      key: "POT_HDTT",
      code: "POT_HDTT",
      name: "Hướng dẫn thủ tục",
      parentCode: null,
      path: "/huong-dan",
      ordinal: 1,
      children: [],
    },
    {
      key: "POT_TB",
      code: "POT_TB",
      name: "Thông báo",
      parentCode: null,
      path: "/thong-bao",
      ordinal: 1,
      children: [],
    },
  ];

  useEffect(() => {
    setMenu(menuCustom);
  }, []);

  //! render
  return (
    <div
      className="wrap-header"
      style={{ position: "sticky", top: 0, zIndex: 99 }}
    >
      <div
        className="header"
        style={{
          width: !md ? "100%" : "calc(100% - 100px)",
          justifyContent: !md ? "space-between" : "unset",
          padding: !md ? 0 : "0 50px",
          position: "relative",
          maxWidth: "1300px",
        }}
      >
        {!md && (
          <ButtonAntd
            type="text"
            icon={
              <MenuOutlined style={{ fontSize: "24px", color: "#AE7D00" }} />
            }
            onClick={() => setOpenMiniMenu(true)}
            style={{
              width: 60,
              height: 75,
            }}
          />
        )}
        <div
          style={{
            ...(!md && {
              cursor: "pointer",
              width: "100%",
              marginRight: "40px",
              position: "absolute",
              top: "10px",
              left: "calc(50% - 25px)",
            }),
          }}
        >
          <Image
            src={Logo}
            preview={false}
            width={50}
            height={50}
            onClick={() => (window.location.href = "/")}
          />
        </div>
        {md && (
          <div className="text-welcome">
            CỔNG HÀNH CHÍNH ĐIỆN TỬ
            <br /> GIÁO HỘI PHẬT GIÁO VIỆT NAM
          </div>
        )}
        {md && <div className="header"></div>}
        <div className="header-login__button">
          <Button
            title="Đăng nhập"
            className="custom-button"
            onClick={handleLogin}
          />
        </div>
      </div>
      {md && (
        <div className="header-menu">
          <div className="group-menu">
            {menu?.map((e: any, index: number) => {
              let checkActive = pathName.includes(e.path) && e.path !== "/";

              if (pathName === "/" && e.path === "/") {
                checkActive = true;
              }

              return (
                <div
                  key={index}
                  style={{
                    marginLeft: 50,
                    fontSize: checkActive ? 18 : 16,
                    lineHeight: checkActive ? "21px" : "19px",
                    fontWeight: checkActive ? 700 : 400,
                    cursor: "pointer",
                  }}
                  className={checkActive ? "isActive" : ""}
                  onClick={() => {
                    const getPath: any = (e: any) => {
                      if (e.children.length) {
                        return getPath(e.children[0]);
                      } else {
                        return e.path;
                      }
                    };

                    window.location.href = getPath(e);
                    // window.location.href = e.children.length
                    //   ? e.children[0].path
                    //   : e.path;
                  }}
                >
                  {e.name}
                </div>
              );
            })}
          </div>
        </div>
      )}

      {!md && (
        <Drawer
          placement={"top"}
          closable={false}
          onClose={() => setOpenMiniMenu(false)}
          open={openMiniMenu}
          bodyStyle={{ padding: 0 }}
          height={"100vh"}
        >
          <div
            className="header"
            style={{
              width: !md ? "100%" : "calc(100% - 80px)",
              justifyContent: !md ? "space-between" : "unset",
              padding: !md ? 0 : "0 40px",
              backgroundColor: "#fff",
              position: "relative",
            }}
          >
            <ButtonAntd
              type="text"
              icon={
                <CloseOutlined style={{ fontSize: "24px", color: "#AE7D00" }} />
              }
              onClick={() => setOpenMiniMenu(false)}
              style={{
                width: 60,
                height: 75,
              }}
            />
            <div className="header-operation">
              <Dropdown menu={{ items }} placement="bottom">
                <Avatar
                  style={{
                    backgroundColor: "#AE7D00",
                    verticalAlign: "middle",
                  }}
                  size="large"
                >
                  {user?.name && user?.name?.slice(0, 2)}
                </Avatar>
              </Dropdown>
            </div>
          </div>
          <div
            style={{
              cursor: "pointer",
              width: "100%",
              marginRight: "40px",
              position: "absolute",
              top: "10px",
              left: "calc(50% - 25px)",
            }}
          >
            <Image
              src={Logo}
              preview={false}
              width={50}
              height={50}
              onClick={() => (window.location.href = "/")}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "top",
              backgroundColor: "#8A5A16",
              color: "#fff",
              height: "100vh",
              gap: "12px",
              paddingTop: "12px",
            }}
          >
            {menu?.map((e: any, index: number) => {
              return (
                <div
                  key={index}
                  style={{
                    fontSize: 24,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const getPath: any = (e: any) => {
                      if (e.children.length) {
                        return getPath(e.children[0]);
                      } else {
                        return e.path;
                      }
                    };

                    window.location.href = getPath(e);
                    // window.location.href = e.children.length
                    //   ? e.children[0].path
                    //   : e.path;
                  }}
                >
                  {e.name}
                </div>
              );
            })}
          </div>
        </Drawer>
      )}
    </div>
  );
};

export default HeaderLogin;
