export const actions = {
    CHECK_AUTH: "CHECK_AUTH",
    CHECK_AUTH_SUCCESS: "CHECK_AUTH_SUCCESS", 
    CHECK_AUTH_FAILED: "CHECK_AUTH_SUCCESS",

    LOGIN: "LOGIN",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILED: "LOGIN_FAILED",

    LOGOUT: "LOGOUT"
}