import { Col, Image, Row, Grid } from "antd";
import Logo from "../../assets/images/logoBMN.svg";

const Footer = () => {
  const { useBreakpoint } = Grid;
  const { md } = useBreakpoint();

  return (
    <div>
      <div className="footer">
        <div
          className="max-width-1200"
          style={{
            margin: "auto",
            paddingTop: "40px",
            paddingLeft: "12px",
          }}
        >
          <div
            className="footer__content"
            style={{
              display: "flex",
              gap: "12px",
              alignItems: "center",
              paddingBottom: "12px",
            }}
          >
            <Image src={Logo} width={60} height={60} preview={false} />
            <div className="text-logofooter">
              CỔNG HÀNH CHÍNH ĐIỆN TỬ GIÁO HỘI PHẬT GIÁO VIỆT NAM
            </div>
          </div>
          <Row gutter={[10, 6]}>
            <Col md={12} className="">
              <div className="footer-text-address">
                <b>Văn phòng trung ương GHPGVN</b> <br />
                Chùa Quán Sứ, số 73 phố Quán Sứ, Hà Nội. <br />
                ĐT: (04) 39422427; Fax: (04) 38223345; <br />
                Email: vp1giaohoi@gmail.com.
              </div>
            </Col>
            <Col md={12}>
              <div className="footer-text-address">
                <b>Văn phòng 2 trung ương GHPGVN</b> <br />
                Thiền Viện Quảng Đức, số 294 Nam Kỳ Khởi Nghĩa, Quận 3, TP.HCM{" "}
                <br /> ĐT: (08) 38483080; Fax: (08) 28469931 <br />
                Email: vitinhvp2@yahoo.com
              </div>
            </Col>
            <Col span={24}>
              <div style={{ display: "flex", gap: 12 }}>
                <a
                  href="/chinh-sach"
                  style={{ fontWeight: "bold", color: "#ae7d00" }}
                >
                  Chính sách quyền riêng tư
                </a>
                <a
                  href="/dieu-khoan"
                  style={{ fontWeight: "bold", color: "#ae7d00" }}
                >
                  Điều khoản sử dụng dịch vụ
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
    //  <Image src={Logo} width={60} height={60} preview={false} />
  );
};

export default Footer;
