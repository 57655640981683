import { Avatar, Button, Drawer, Dropdown, Image, MenuProps } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import Logo from "../../assets/images/logoBMN.svg";
import { actions } from "../../redux/Actions";
import authServices from "../../services/authServices";
import IconInformation from "../icons/icon_information";
import IconLogout from "../icons/icon_logout";
import { Grid } from "antd";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";

const Header = () => {
  // const menu = authServices.getUserInStorage("menu");
  const user = authServices.getUserInStorage("user");
  const { useBreakpoint } = Grid;
  const { md } = useBreakpoint();
  const [openMiniMenu, setOpenMiniMenu] = useState<boolean>(false);
  const [popupCapTT, setPopupCapTT] = useState<boolean>(
    !user?.trucThuocHienTai
  );
  const [items, setItems] = useState<MenuProps["items"]>([]);

  /*menu.unshift({
        key: "HOME",
        name: "Trang chủ",
        path: "/",
        children: [],
        parentCode: null,
    });*/

  //! define
  const dispatch = useDispatch();

  //! state

  const location = useLocation();
  const pathName = location.pathname;

  //! function
  const handleLogout = () => {
    dispatch({ type: actions.LOGOUT });
  };

  const [menu, setMenu] = useState([]);

  const menuCustom: any = [
    {
      key: "POT_HOME",
      code: "POT_HOME",
      name: "Trang chủ",
      parentCode: null,
      path: "/",
      ordinal: 1,
      children: [],
    },
    {
      key: "POT_KKTT",
      code: "POT_KKTT",
      name: "Kê khai trực tuyến",
      parentCode: null,
      path: "/ke-khai-tt",
      ordinal: 1,
      children: [],
    },
    {
      key: "POT_TCHS",
      code: "POT_TCHS",
      name: "Tra cứu hồ sơ",
      parentCode: null,
      path: "/tra-cuu-hs",
      ordinal: 1,
      children: [],
    },
    {
      key: "POT_HDTT",
      code: "POT_HDTT",
      name: "Hướng dẫn thủ tục",
      parentCode: null,
      path: "/huong-dan",
      ordinal: 1,
      children: [],
    },
    {
      key: "POT_TB",
      code: "POT_TB",
      name: "Thông báo",
      parentCode: null,
      path: "/thong-bao",
      ordinal: 1,
      children: [],
    },
  ];

  useEffect(() => {
    setMenu(menuCustom);
  }, []);

  const handleInfomation = () => {
    window.location.href = "/thong-tin-cn";
  };

  const handleCapTrucThuoc = () => {
    setPopupCapTT(true);
  };

  const itemsCustom: MenuProps["items"] = [
    {
      key: 1,
      label: (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            style={{
              backgroundColor: "#AE7D00",
              verticalAlign: "middle",
              marginRight: 10,
            }}
            size="default"
          >
            {user?.name && user?.name?.slice(0, 2)}
          </Avatar>
          <div>
            <div
              style={{
                fontSize: 18,
                lineHeight: "20px",
                fontWeight: 600,
                textTransform: "capitalize",
              }}
            >
              {user?.name?.toLowerCase()}
            </div>
            <div
              style={{
                fontSize: 12,
                lineHeight: "14px",
                fontWeight: 600,
              }}
            >
              {user?.username}
              <br />
              {user?.trucThuocHienTai?.tenCaptrucThuoc || ""}
            </div>
          </div>
        </div>
      ),
    },
    /* {
      key: 2,
      label: (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={handleCapTrucThuoc}
        >
          <IconStorage />
          <div
            style={{
              fontSize: 16,
              lineHeight: "24px",
              fontWeight: 600,
              marginLeft: 10,
              color: "#8A5A16",
            }}
          >
            Chọn cấp trực thuộc
          </div>
        </div>
      ),
    }, */
    {
      key: 3,
      label: (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={handleInfomation}
          className={pathName === "/thong-tin-cn" ? "isActive" : ""}
        >
          <IconInformation />
          <div
            style={{
              fontSize: 16,
              lineHeight: "24px",
              fontWeight: 600,
              marginLeft: 10,
              color: "#8A5A16",
            }}
          >
            Thông tin cá nhân
          </div>
        </div>
      ),
    },
    // {
    //   key: 4,
    //   label: (
    //     <div style={{ display: "flex", alignItems: "center" }}>
    //       <IconStorage />
    //       <div
    //         style={{
    //           fontSize: 16,
    //           lineHeight: "24px",
    //           fontWeight: 600,
    //           marginLeft: 10,
    //           color: "#8A5A16",
    //         }}
    //       >
    //         Kho dữ liệu
    //       </div>
    //     </div>
    //   ),
    // },
    // {
    //   key: 5,
    //   label: (
    //     <div style={{ display: "flex", alignItems: "center" }}>
    //       <IconSetting />
    //       <div
    //         style={{
    //           fontSize: 16,
    //           lineHeight: "24px",
    //           fontWeight: 600,
    //           marginLeft: 10,
    //           color: "#8A5A16",
    //         }}
    //       >
    //         Cài đặt
    //       </div>
    //     </div>
    //   ),
    // },
    {
      key: 6,
      label: (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={handleLogout}
        >
          <IconLogout />
          <div
            style={{
              fontSize: 16,
              lineHeight: "24px",
              fontWeight: 600,
              marginLeft: 10,
              color: "#8A5A16",
            }}
          >
            Thoát
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    let menuCustom: MenuProps["items"] = [];
    if (!user?.danhSachTrucThuoc || user?.danhSachTrucThuoc.length <= 1) {
      if (user?.userType === "BUDDHIST") {
        for (let item of itemsCustom) {
          if (item?.key != 3 && item?.key != 2) {
            menuCustom.push(item);
          }
        }
        setItems(menuCustom);
      } else {
        for (let item of itemsCustom) {
          if (item?.key != 2) {
            menuCustom.push(item);
          }
        }
        setItems(menuCustom);
      }
    } else {
      if (user?.userType === "BUDDHIST") {
        for (let item of itemsCustom) {
          if (item?.key != 3) {
            menuCustom.push(item);
          }
        }
        setItems(menuCustom);
      } else {
        setItems(itemsCustom);
      }
    }
  }, []);

  //! useEffect

  //! render
  return (
    <div
      className="wrap-header"
      style={{ position: "sticky", top: 0, zIndex: 99 }}
    >
      <div
        className="header"
        style={{
          width: !md ? "100%" : "calc(100% - 100px)",
          justifyContent: !md ? "space-between" : "unset",
          padding: !md ? 0 : "0 50px",
          position: "relative",
          maxWidth: "1300px",
        }}
      >
        {!md && (
          <Button
            type="text"
            icon={
              <MenuOutlined style={{ fontSize: "24px", color: "#AE7D00" }} />
            }
            onClick={() => setOpenMiniMenu(true)}
            style={{
              width: 60,
              height: 75,
            }}
          />
        )}
        <div
          style={{
            ...(!md && {
              cursor: "pointer",
              width: "100%",
              marginRight: "40px",
              position: "absolute",
              top: "10px",
              left: "calc(50% - 25px)",
            }),
          }}
        >
          <Image
            src={Logo}
            preview={false}
            width={50}
            height={50}
            onClick={() => (window.location.href = "/")}
          />
        </div>
        {md && (
          <div className="text-welcome">
            CỔNG HÀNH CHÍNH ĐIỆN TỬ
            <br /> GIÁO HỘI PHẬT GIÁO VIỆT NAM
          </div>
        )}
        {md && <div className="header"></div>}
        <div className="header-operation">
          <Dropdown menu={{ items }} placement="bottom">
            {!md ? (
              <Avatar
                style={{ backgroundColor: "#AE7D00", verticalAlign: "middle" }}
                size="large"
              >
                {user?.name && user?.name?.slice(0, 2)}
              </Avatar>
            ) : (
              <div
                style={{ display: "flex", gap: "12px", alignItems: "center" }}
              >
                <IconInformation />
                <div
                  style={{
                    color: "#6C381C",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  {user?.name}
                </div>
              </div>
            )}
          </Dropdown>
        </div>
      </div>
      {md && (
        <div className="header-menu">
          <div className="group-menu">
            {menu?.map((e: any, index: number) => {
              let checkActive = pathName.includes(e.path) && e.path !== "/";

              if (pathName === "/" && e.path === "/") {
                checkActive = true;
              }

              return (
                <div
                  key={index}
                  style={{
                    marginLeft: 50,
                    fontSize: checkActive ? 18 : 16,
                    lineHeight: checkActive ? "21px" : "19px",
                    fontWeight: checkActive ? 700 : 400,
                    cursor: "pointer",
                  }}
                  className={checkActive ? "isActive" : ""}
                  onClick={() => {
                    const getPath: any = (e: any) => {
                      if (e.children.length) {
                        return getPath(e.children[0]);
                      } else {
                        return e.path;
                      }
                    };

                    window.location.href = getPath(e);
                    // window.location.href = e.children.length
                    //   ? e.children[0].path
                    //   : e.path;
                  }}
                >
                  {e.name}
                </div>
              );
            })}
          </div>
        </div>
      )}

      {!md && (
        <Drawer
          placement={"top"}
          closable={false}
          onClose={() => setOpenMiniMenu(false)}
          open={openMiniMenu}
          bodyStyle={{ padding: 0 }}
          height={"100vh"}
        >
          <div
            className="header"
            style={{
              width: !md ? "100%" : "calc(100% - 80px)",
              justifyContent: !md ? "space-between" : "unset",
              padding: !md ? 0 : "0 40px",
              backgroundColor: "#fff",
              position: "relative",
            }}
          >
            <Button
              type="text"
              icon={
                <CloseOutlined style={{ fontSize: "24px", color: "#AE7D00" }} />
              }
              onClick={() => setOpenMiniMenu(false)}
              style={{
                width: 60,
                height: 75,
              }}
            />
            <div className="header-operation">
              <Dropdown menu={{ items }} placement="bottom">
                <Avatar
                  style={{
                    backgroundColor: "#AE7D00",
                    verticalAlign: "middle",
                  }}
                  size="large"
                >
                  {user?.name && user?.name?.slice(0, 2)}
                </Avatar>
              </Dropdown>
            </div>
          </div>
          <div
            style={{
              cursor: "pointer",
              width: "100%",
              marginRight: "40px",
              position: "absolute",
              top: "10px",
              left: "calc(50% - 25px)",
            }}
          >
            <Image
              src={Logo}
              preview={false}
              width={50}
              height={50}
              onClick={() => (window.location.href = "/")}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "top",
              backgroundColor: "#8A5A16",
              color: "#fff",
              height: "100vh",
              gap: "12px",
              paddingTop: "12px",
            }}
          >
            {menu?.map((e: any, index: number) => {
              return (
                <div
                  key={index}
                  style={{
                    fontSize: 24,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    const getPath: any = (e: any) => {
                      if (e.children.length) {
                        return getPath(e.children[0]);
                      } else {
                        return e.path;
                      }
                    };
                    window.location.href = getPath(e);
                  }}
                >
                  {e.name}
                </div>
              );
            })}
          </div>
        </Drawer>
      )}
    </div>
  );
};

export default Header;
