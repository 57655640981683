export const BASE_URL = process.env.REACT_APP_BMS_URL;

export const BASE_CATEGORY_URL = `${BASE_URL}/api/category`;
// export const BASE_CATEGORY_URL = `http://localhost:8010`;
export const BASE_MANAGEMENT_TANGNI_URL = `${BASE_URL}/api/management/tang-ni`;
export const BASE_MANAGEMENT_PHATTU_URL = `${BASE_URL}/api/management/phat-tu`;
export const BASE_MANAGEMENT_POST_URL = `${BASE_URL}/api`;
export const BASE_SECURITY_URL = `${BASE_URL}/api/security`;
export const BASE_MANAGEMENT_QUYYTAMBAO_URL = `${BASE_URL}/api/management/quy-y-tam-bao`;
export const BASE_PORTAL_URL = `${BASE_URL}/api/portal-service`;
// export const BASE_PORTAL_URL =`http://localhost:8085`;

export const BASE_STORAGE_URL = `${BASE_URL}/api/storage`;

// export const URL_TOKEN = `${process.env.REACT_APP_SSO_VNEID}/api/idp-sso/sso/auth/token`
export const URL_TOKEN = `${BASE_URL}/api/security/authenticate`;
export const URL_LOGOUT = process.env.REACT_APP_IDP_URL;

export const AuthApi = {
  LOGIN: "/auth/login",
};

export const IDPApi = {
  GET_INFO: "/lookup-idp",
  TRA_CUU_INFO: "/ho-so/tra-cuu-info"
};
