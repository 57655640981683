import { lazy } from "react";
import { PathRoute } from "./path";
import { Outlet } from "react-router-dom";
const XacThuc = lazy(() => import("../../pages/XacThuc/index"));

const Home = lazy(() => import("../../pages/Home/index"));
const Page404 = lazy(() => import("../../pages/Page404/index"));
const Dashboard = lazy(() => import("../../pages/Dashboard/index"));
const Login = lazy(() => import("../../pages/Login/index"));
const KeKhaiTrucTuyen = lazy(() => import("../../pages/KeKhaiTrucTuyen/index"));
const TraCuuHoSo = lazy(() => import("../../pages/TraCuuHoSo/index"));
const ThongTinCaNhan = lazy(() => import("../../pages/ThongTinCaNhan/index"));
const GioiThieu = lazy(() => import("../../pages/GioiThieu/index"));
const HoTro = lazy(() => import("../../pages/HoTro/index"));
const HuongDanThuTuc = lazy(() => import("../../pages/HuongDanThuTuc/index"));
const ThongBao = lazy(() => import("../../pages/ThongBao/index"));
const DieuKhoan = lazy(
  () => import("../../pages/ChinhSach-DieuKhoan/DieuKhoan")
);
const ChinhSach = lazy(
  () => import("../../pages/ChinhSach-DieuKhoan/ChinhSach")
);

const router = [
  {
    path: PathRoute.Home,
    name: "Home",
    component: <Home />,
    isPrivate: true,
  },
  {
    path: PathRoute.DieuKhoan,
    name: "DieuKhoan",
    component: <DieuKhoan />,
    isPrivate: true,
  },
  {
    path: PathRoute.ChinhSach,
    name: "ChinhSach",
    component: <ChinhSach />,
    isPrivate: true,
  },
  {
    path: PathRoute.XacThuc,
    name: "XacThuc",
    component: <XacThuc />,
    isPrivate: true,
  },
  {
    path: PathRoute.Dashboard,
    name: "Dashboard",
    component: <Dashboard />,
    isPrivate: true,
  },
  {
    path: PathRoute.Login,
    name: "Login",
    component: <Login />,
    isPrivate: false,
  },
  {
    path: PathRoute.Page404,
    name: "404",
    component: <Page404 />,
    isPrivate: false,
  },
  {
    path: "/*",
    name: "404",
    component: <Page404 />,
    isPrivate: false,
  },
  {
    path: PathRoute.KeKhaiTrucTuyen,
    name: "KeKhaiTrucTuyen",
    component: <KeKhaiTrucTuyen />,
    isPrivate: true,
  },
  {
    path: PathRoute.TraCuuHoSo,
    name: "TraCuuHoSo",
    component: <TraCuuHoSo />,
    isPrivate: true,
  },
  {
    path: PathRoute.ThongTinCaNhan,
    name: "ThongTinCaNhan",
    component: <ThongTinCaNhan />,
    isPrivate: true,
  },
  {
    path: PathRoute.GioiThieu,
    name: "GioiThieu",
    component: <GioiThieu />,
    isPrivate: false,
  },
  {
    path: PathRoute.HoTro,
    name: "HoTro",
    component: <HoTro />,
    isPrivate: false,
  },
  {
    path: PathRoute.ThongBao,
    name: "ThongBao",
    component: <ThongBao />,
    isPrivate: false,
  },
  {
    path: PathRoute.HuongDanThuTuc,
    name: "HuongDanThuTuc",
    component: <HuongDanThuTuc />,
    isPrivate: false,
  },
];

export default router;
