import React from "react";

const IconInformation = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7643 20.572C22.3147 20.572 25.1928 17.6938 25.1928 14.1434C25.1928 10.593 22.3147 7.71484 18.7643 7.71484C15.2139 7.71484 12.3357 10.593 12.3357 14.1434C12.3357 17.6938 15.2139 20.572 18.7643 20.572Z"
        stroke="#8A5A16"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M7.7843 30.5976C8.93178 28.7141 10.5445 27.1574 12.4674 26.0772C14.3903 24.997 16.5588 24.4297 18.7643 24.4297C20.9698 24.4297 23.1383 24.997 25.0612 26.0772C26.9841 27.1574 28.5968 28.7141 29.7443 30.5976"
        stroke="#8A5A16"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M18.7643 34.7137C27.9953 34.7137 35.4786 27.2305 35.4786 17.9994C35.4786 8.7684 27.9953 1.28516 18.7643 1.28516C9.53323 1.28516 2.04999 8.7684 2.04999 17.9994C2.04999 27.2305 9.53323 34.7137 18.7643 34.7137Z"
        stroke="#8A5A16"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );
};

export default IconInformation;
