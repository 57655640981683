import { put, takeLatest } from "redux-saga/effects";
import { call } from "typed-redux-saga";
import { BASE_SECURITY_URL } from "../../constants/apis";
import { GRANT_TYPE_AUTH } from "../../constants/global";
import { PathRoute } from "../../constants/router/path";
import {
  default as AuthService,
  default as authServices,
} from "../../services/authServices";
import httpMethod from "../../services/httpMethod";
import { actions } from "../Actions";
import hoSoThuGioiServices from "../../services/hoSoThuGioiServices";

function* checkAuth({ payload }: any) {
  const userStorage = authServices.getUserInStorage("token");
  if (userStorage) {
    httpMethod.attachTokenToHeader(userStorage?.token);
    yield put({ type: actions.LOGIN_SUCCESS, payload: { user: userStorage } });
  } else {
    yield put({ type: actions.LOGIN_FAILED });
  }
  yield put({ type: actions.CHECK_AUTH_SUCCESS });
}
const getProfile = async () => {
  const res = await httpMethod.get(`${BASE_SECURITY_URL}/profile`);
  if (res.status == 200) {
    if (res.data?.userType !== "MONK" && res.data?.userType !== "BUDDHIST") {
      window.localStorage.clear();
      await logoutBms();
      window.location.href = `${process.env.REACT_APP_IDP_URL}?client_id=${
        process.env.REACT_APP_IDP_CLIENT_ID
      }&redirect_uri=${encodeURIComponent(
        `${window.location.origin}/login?error=1`
      )}&response_mode=fragment&logout=true`;
      throw new Error("User don't have permission!");
    }
    await AuthService.putUserToStorage("user", res.data);
  } else {
    window.localStorage.clear();
    await logoutBms();
    window.location.href = `${process.env.REACT_APP_IDP_URL}?client_id=${
      process.env.REACT_APP_IDP_CLIENT_ID
    }&redirect_uri=${encodeURIComponent(
      `${window.location.origin}/login?error=1`
    )}&response_mode=fragment&logout=true`;
    throw new Error("User don't have permission!");
  }
};
const getMenu = async () => {
  await AuthService.putUserToStorage("menu", []);
};

const logoutBms = async () => {
  await httpMethod.post(`${BASE_SECURITY_URL}/logout`, "");
};

function* login({ payload }: any) {
  const { code } = payload;
  try {
    const response = yield* call(AuthService.getToken, {
      code,
      grantType: GRANT_TYPE_AUTH,
      client_id: process.env.REACT_APP_IDP_CLIENT_ID,
      redirect_uri: `${window.location.origin}/xac-thuc`,
      // client_secret: CLIENT_SECRET,
    });
    if (response.status === 200) {
      const token = {
        token: response.data.token,
      };

      AuthService.putUserToStorage("token", token);

      httpMethod.attachTokenToHeader(response.data.token);
      yield call(getProfile);
      yield call(getMenu);
      window.location.href = PathRoute.Home;
    }
  } catch (error) {
    // toast error message
    // window.location.href = PathRoute.Login;
  }
}

function* logout() {
  yield window.localStorage.clear();
  yield put({ type: actions.LOGIN_FAILED });
  yield call(logoutBms);
  window.location.href = `${process.env.REACT_APP_IDP_URL}?client_id=${
    process.env.REACT_APP_IDP_CLIENT_ID
  }&redirect_uri=${encodeURIComponent(
    `${window.location.origin}/login`
  )}&response_mode=fragment&logout=true`;
}

export default function* rootSaga() {
  yield takeLatest(actions.LOGIN, login);
  yield takeLatest(actions.CHECK_AUTH, checkAuth);
  yield takeLatest(actions.LOGOUT, logout);
}
