import React, { createContext, Suspense, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../src/styles/styles.scss";
import "./App.css";
import SuspenseLoader from "./components/SuspenseLoader";
import router from "./constants/router/router";
import DefaultLayout from "./layout/DefautLayout";
import authServices from "./services/authServices";
import DefaultNotAuth from "./layout/DefaultNotAuth";

interface IAppContext {
  setLoading: (e: boolean) => void;
  isLoading: boolean;
}
export const AppContext = createContext<IAppContext>({
  setLoading: () => null,
  isLoading: false,
});
const App = () => {
  //! define
  const token = authServices.getUserInStorage("token");

  const [isLoading, setLoading] = useState(false);

  //! render
  return (
    <AppContext.Provider
      value={{
        setLoading,
        isLoading,
      }}
    >
      <Suspense fallback={<SuspenseLoader />}>
        <BrowserRouter>
          {isLoading && <SuspenseLoader />}
          {!token ? (
            <Routes>
              {router?.map((item) => {
                if (item.path === "/login" || item.path === "/") {
                  return (
                    <Route
                      path={item.path}
                      element={item.component}
                      key={item.path}
                    />
                  );
                } else
                  return (
                    <Route
                      path={item.path}
                      element={
                        <DefaultNotAuth>{item.component}</DefaultNotAuth>
                      }
                      key={item.path}
                    />
                  );
              })}
            </Routes>
          ) : (
            <DefaultLayout>
              <Routes>
                {router?.map((item) => {
                  return (
                    <Route
                      path={item.path}
                      element={item.component}
                      key={item.path}
                    />
                  );
                })}
              </Routes>
            </DefaultLayout>
          )}
        </BrowserRouter>
      </Suspense>
    </AppContext.Provider>
  );
};

export default App;
