import { isString } from "lodash";
import {AuthApi, BASE_URL, URL_LOGOUT, URL_TOKEN, BASE_SECURITY_URL, IDPApi, BASE_PORTAL_URL} from "../constants/apis";
import { ResponseGenerator } from "../interfaces";
import httpService from "./httpMethod";
import httpMethod from "./httpMethod";
import { IBody } from "../hooks/useGetInfoIDP";

const USER_STORAGE = "USER_STORAGE";

export type AuthSignInI = ResponseGenerator<{
  user: {
    email: string;
    fullname: string;
  };
  access_token: string;
}>;
class AuthService {
  login(data: { username: string; password: string }): Promise<any> {
    return httpService.post(`${BASE_URL}${AuthApi.LOGIN}`, data);
  }

  getToken(data: {
    code: any;
    grantType: any;
    client_id: any;
    redirect_uri: any;
  }): Promise<any> {
    return httpService.post(`${URL_TOKEN}`, { //?code=${data.code}&client_id=${data.client_id}
      'code': data.code,
      'client_id': data.client_id,
    },{
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }
  // vertifyIdp(data:any){
  //   return httpMethod.post(
  //     `${BASE_SECURITY_URL}/verify-idp`,data);
  // }

  getInfoIDP(body: IBody) {
    return httpMethod.post(`${BASE_SECURITY_URL}${IDPApi.GET_INFO}`, body);
    // return httpMethod.get(`${BASE_PORTAL_URL}${IDPApi.TRA_CUU_INFO}`, body);
  }

  putUserToStorage(name?: any, data?: any) {
    window.localStorage.setItem(name, JSON.stringify(data));
  }

  getUserInStorage(name?: any) {
    const user = window.localStorage.getItem(name);
    if (user && isString(user)) {
      return JSON.parse(user);
    }

    return null;
  }

  clearUser() {
    window.localStorage.removeItem("token");
  }
}

export default new AuthService();
